import React from 'react';
import {useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { 
  useMutation
, gql 
//,useApolloClient
} from "@apollo/client"

import { InputText } from 'primereact/inputtext';
import { DropdownGQL } from './DropdownGQL.tsx';

export const LicenseKeyDialog = ({visible, onHide, licenseKey}) => {
    //const apolloClient = useApolloClient();

    const GET_LIST_OF_VALUES = gql`
    query list_of_values {
        questionnaires {
            code:id
            name
        }
    }
    `;

    const GET_LIST_OF_USERS = gql`
    query list_of_values_users {
        users {
            id
            code:id
            name
            email
        }
    }
    `;

    const SET_LICENCE_KEY = gql`
    mutation license_key_create(
      $id: Int
    , $candidateEmail: String
    , $candidateName: String
    , $description: String
    , $questionnaireId: Int
    ) {
      license_key_mutation(
        id: $id
      , candidateEmail: $candidateEmail
      , candidateName: $candidateName
      , description: $description
      , questionnaireId: $questionnaireId
      ) {
        code
        success
        message
        licenseKey {
            licenseKey
            id
            description
            createdBy {
                id
                code:id
                name
                email
            }
            candidate {
                id
                code:id
                name
                email
            }
            questionnaire {
                code:id
                name
            }
        }
      }
    }
    `
    const [candidateName, setCandidateName] = useState(licenseKey?.candidate?.name || "");
    const [license_key_component, setLicenseKey] = useState(licenseKey);
    useEffect(() => {
        setCandidateName(licenseKey?.candidate?.name || "");
        setLicenseKey(licenseKey);

      }, [licenseKey]); 

      

    const [SetLicenseKey] = useMutation(SET_LICENCE_KEY, {
        variables: {},
        onCompleted: ({license_key_mutation}) => {
            onHide(license_key_mutation);
        },
        onError: (error =>{
            alert(`SetLicenseKey.onError ${error}`)
            //console.error(error)
        }),
     
        refetchQueries: [
            "list_of_values_users","licenseKey_query"
        ],
     
/*  
        update(cache, updateData) {            
            const addUser = updateData?.data?.license_key_mutation?.licenseKey?.candidate;
            if(addUser)
            cache.modify({
              fields: {
                users(existingUsers = []) {
                  const newUseRef = cache.writeFragment({
                    data: addUser,
                    fragment: gql`
                      fragment NewUser on User {
                        id
                        code
                        name
                        email
                      }
                    `
                  });
                  return [...existingUsers, newUseRef];
                }
              }
            });
        }
*/  
    });

    
    const saveLicenseKey = () => {        
        let candidateEmail : String = "";

        if(license_key_component.candidate) {
            if(typeof license_key_component.candidate === "string") {
                candidateEmail=license_key_component.candidate;
            } else {
                candidateEmail=license_key_component.candidate.email;
            }
        }

        const variables = { 
          id: license_key_component.id || 0
        , description: license_key_component.description
        , candidateEmail: candidateEmail
        , candidateName: candidateName
        , questionnaireId: license_key_component?.questionnaire?.code
        }

        SetLicenseKey({
            variables: variables
        }); 
    };

    const onInputChange = (e, name : String) => {
        const val = (e.target && e.target.value) || '';
        let _licenseKey = { ...license_key_component };
        const names = name.split(".");
        switch(names.length) {
            case 1: 
                _licenseKey[names[0]] = val; 
                break;
            case 2: 
                _licenseKey[names[0]] = _licenseKey[names[0]] || {};
                _licenseKey[names[0]][names[1]] = val;                
                break;
        }        
        if(name==="candidateName") setCandidateName(val);
        //_licenseKey[`${name}`] = val;
        setLicenseKey(_licenseKey);
    };
    
    const licenseKeyDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Save" icon="pi pi-check" onClick={saveLicenseKey} />            
        </React.Fragment>
    );

    return(
    <Dialog visible={visible} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="License Key details" modal className="p-fluid" footer={licenseKeyDialogFooter} onHide={onHide}>
    <div className="field">
        <label htmlFor="candidate" className="font-bold">
            Candidato o correo electrónico nuevo
        </label>
        <DropdownGQL id="candidate" placeholder="Elija la persona que sera evaluada"              
            value={license_key_component.candidate}
            editable
            onChange={(e) => onInputChange(e, 'candidate')}
            gqlValues={GET_LIST_OF_USERS} gqlData="users" 
        />
    </div>
    <div className="field">
        <label htmlFor="candidateName" className="font-bold">
            Nombre del Candidato
        </label>
        <InputText id="candidateName" value={candidateName} onChange={(e) => onInputChange(e, 'candidateName')} autoFocus  />
    </div>
    <div className="field">
        <DropdownGQL id="questionnarie" placeholder="Elija el questionario a utilizar" label="Questionario"
            value={license_key_component.questionnaire}
            editable
            onChange={(e) => onInputChange(e, 'questionnaire')}
            gqlValues={GET_LIST_OF_VALUES} gqlData="questionnaires"                
        />
    </div>

    </Dialog>
    )
}
/*
    <div className="field">
        <label htmlFor="description" className="font-bold">
            Description
        </label>
        <InputTextarea id="description" value={license_key_component.description} onChange={(e) => onInputChange(e, 'description')} autoFocus rows={3} cols={20} />
    </div>
    <div className="field">
        <label htmlFor="licenseKey" className="font-bold">
            License Key
        </label>
        <InputText disabled  id="licenseKey" value={license_key_component.licenseKey} onChange={(e) => onInputChange(e, 'licenseKey')}  />
    </div>
*/

export const LicenseKeyDialogSend = ({visible, onHide, licenseKey}) => {

    const SEND_LICENSE_KEY = gql`
    mutation licensekey_send($id: Int!) {
        license_key_send(id:$id)
      }
    `

    const [SendLicenseKey] = useMutation(SEND_LICENSE_KEY, {
        variables: {},
        onCompleted: ({licensekey_send}) => {          
          onHide(licensekey_send);
        },
      });

    const sendLicenseKey = () => {
        const variables = { 
            id: licenseKey?.id || 0
        }
        SendLicenseKey({
            variables: variables
        });        
    };

    const licenseKeyDialogFooter = 
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Si" icon="pi pi-check" onClick={sendLicenseKey} />
        </React.Fragment>
    ;

    return(
        <Dialog visible={visible} onHide={onHide}
            style={{ width: '32rem' }} 
            breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
            header="Confirmar envio de notificación" modal className="p-fluid" 
            footer={licenseKeyDialogFooter}
        >            
            <div className="confirmation-content">
                <div className="p-fluid" >                    
                    <span style={{ fontSize: '1.4rem' }}>⚠️ ¿Esta seguro que desea notificar al coachee?</span>
                </div>
                <p>
                    Coachee <b>{licenseKey?.candidate?.name || licenseKey?.candidate?.email}</b><br/>
                </p>
            </div>
        </Dialog>
    )
}

export const LicenseKeyDialogDelete = ({visible, onHide, licenseKey}) => {

    const DELETE_LICENCE_KEY = gql`
    mutation license_key_delete($id: Int!) {
        license_key_delete(id:$id) {
          code
          success
          message
          licenseKey {
            id
          }
        }
      }
    `
    const [DeleteLicenseKey] = useMutation(DELETE_LICENCE_KEY, {
        variables: {},
        onCompleted: (data) => {
          onHide(null,data);
        },
    });

    const deleteLicenseKey = () => {
        DeleteLicenseKey({
            variables: { 
              id: licenseKey.id 
            }
        });
    };

    const deleteLicenseKeyDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteLicenseKey} />
        </React.Fragment>
    );

    return(
    <Dialog visible={visible} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteLicenseKeyDialogFooter} onHide={onHide}>
    <div className="confirmation-content">
        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
        {licenseKey && (
            <span>
                Esta seguro que desea eliminar la llave <br/><b>{licenseKey.licenseKey}</b>?
            </span>
        )}
    </div>
    </Dialog>
    )
}

export const LicenseKeyDialogChangeStatus = ({visible, onHide, licenseKey, statusId}) => {

    const CHANGE_STATUS_LICENSE_KEY = gql`
    mutation license_key_status($id: Int!, $statusId: Int) {
        license_key_mutation(id:$id, statusId:$statusId) {
          code
          success
          message
          licenseKey {
            id
            status {
                id
                code:id
                name
                color
            }
          }
        }
      }
    `

    const [ChangeStatus] = useMutation(CHANGE_STATUS_LICENSE_KEY, {
        variables: {
          id: licenseKey?.id || 0
        , statusId:statusId
        },
        onCompleted: (result) => {                    
          setVisibleThankyouDialog(true);
          onHide();
        },
      });

    const [visibleThankyouDialog, setVisibleThankyouDialog] = useState<boolean>(false);
    
    const [newStatusId, setNewStatusId] = useState<number>(statusId);
    useEffect(() => {
        setNewStatusId(statusId);
      }, [statusId]); 

    const changeStatus = () => {
        const variables = { 
          id: licenseKey?.id || 0        
        , statusId: newStatusId
        }
        //console.warn(variables)
        ChangeStatus({
            variables: variables
        });        
    };

    const dialogFooter = 
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Si" icon="pi pi-check" onClick={changeStatus} />     
        </React.Fragment>
    ;

    const PostDialog = () => {
        let message:String="";
        let headerMessage:String="Evaluación ";
        switch(newStatusId) {
            case 3: // Iniciado
                headerMessage += "Iniciada"
                message = "Hemos enviado correos notificando a los evaluadores para que inicien a colaborar con el llenado de sus evaluaciones."
                break;            
            case 4: // Rechazado
                headerMessage += "Rechazada"
                message = "Rechazado"
                break;
            case 5: // Finalizado
                headerMessage += "Finalizada"
                message = "La evaluación ha sido finalizada. Ya no se podrán realizar cambios a las respuestas de los evaluadores."
                break;
        }
        return(
            <Dialog 
                visible={visibleThankyouDialog}
                header={headerMessage}
                footer={<Button label="Ok" icon="pi pi-check" onClick={() => setVisibleThankyouDialog(false)} autoFocus />}
                modal           
                style={{ width: '50rem' }} 
                onHide={() => {if (!visibleThankyouDialog) return; setVisibleThankyouDialog(false); }}
            >{message}
            </Dialog>
        );
    }



    return(
    <>
        <Dialog visible={visible} onHide={onHide}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Confirmar cambio" modal className="p-fluid" 
            footer={dialogFooter}
        >
            <div className="confirmation-content">
                <div className="p-fluid" >
                    <span style={{ fontSize: '1.4rem' }}>⚠️ ¿Esta seguro que desea comenzar la evaluación?</span>
                </div>
                <p>
                    Coach <b>{licenseKey?.createdBy?.name || licenseKey?.createdBy?.email}</b><br/>
                    Coachee <b>{licenseKey?.candidate?.name || licenseKey?.candidate?.email}</b><br/>
                </p>
            </div>
        </Dialog>
        <PostDialog/>
        
    </>
    )
}