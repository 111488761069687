import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";

import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';

import { Toast } from 'primereact/toast';

import { 
  AssesmentCard
, AssesmentDialogChangeStatus  
} from '../components/AssesmentDialog.tsx';

import { Answer } from '../components/Answers.tsx';


export const Assesment = () => {
  const GET_ASSESMENT = gql`
  query AssesmentsByID ($id: Int) {
    assesments (id:$id) {
      id
      name
      description
      completed
      user {
        email
        name
      }
      candidate {
        code:id
        email
        name
      }
      relationship { code:id name color }
      status { code:id name color }
      answers {
        id
        value
        question {
          id
          name
          description
          description_self
          attribute {
            name
            description
            example
            pillars {
              name
              description
              example
            }
          }
        }
      }
    }
  }
  `

    const {assesmentId}=useParams();
    const stepperRef = useRef(null);
    const [changeStatusAssesmentDialog, setChangeStatusAssesmentDialog] = useState<boolean>(false);      
    const toast = useRef<Toast>(null);
    const {loading, error, data} = useQuery(GET_ASSESMENT, {
      variables: {
        id: Number(assesmentId)
      }
    });

    let nodesMine = [];
    let assesment;
    let percentCompleted : number = 0;
    let allowAssesment = {
      add: false
    , edit: false
    , delete: false
    , send: false
    };

    if(data?.assesments) {

      assesment = data.assesments[0]; //.filter(assesment => Number(assesment.id) === Number(assesmentId) )[0]

      let reduced
      if(assesment?.answers) 
        reduced = assesment.answers
        .reduce( function(r,a)  {
          r[a.question.attribute.pillars[0].name] = r[a.question.attribute.pillars[0].name] || [];
          r[a.question.attribute.pillars[0].name][a.question.attribute.name] = r[a.question.attribute.pillars[0].name][a.question.attribute.name] || [];
          r[a.question.attribute.pillars[0].name][a.question.attribute.name].push(a);
          return(r);
        },Object.create(null)) // reduce
        ;

      for(let r in reduced) {

        let nodeMine = {
            key: r
          , label: r
          , icon: "pi pi-fw pi-inbox"
          , children: []          
        }
        
        for(let a in reduced[r] ) {          
          nodeMine.pillar = reduced[r][a][0].question.attribute.pillars[0];
          
          nodeMine.children.push ({
            key: a
          , label: a
          , icon: "pi pi-fw pi-inbox"
          , data: reduced[r][a]
          , attribute: reduced[r][a][0].question.attribute
          })
        }

        nodesMine.push(nodeMine)
        
      }

      percentCompleted = parseFloat(assesment?.completed || 0);
      if(isNaN(percentCompleted)) percentCompleted = 0;
      
      const statusId : number = parseInt(assesment?.status?.code);
      const isNotSelf : boolean = assesment?.user.email !== assesment?.candidate.email;
      //Nuevo 1, Invitado 2, Iniciado 3, Recazado 4, Finalizado 5
      allowAssesment = {
          add:    false
        , edit:   (([1,2,3].includes(statusId))  && isNotSelf )|| false
        , delete: (!([3,5].includes(statusId))   && isNotSelf )|| false
        , view:   !isNotSelf
        , send:   (!([5].includes(statusId))     && isNotSelf )|| false
      };
    }

    const hideChangeStatusDialog = () => {        
      setChangeStatusAssesmentDialog(false);
    };

    const openChangeStatus = () => {
      setChangeStatusAssesmentDialog(true);
    };

    return (
    
      <QueryResult error={error} loading={loading} data={data} >
        <Toast ref={toast} />
        <div className="card flex flex-wrap justify-content-center gap-2">
        <div>
        {assesment && <AssesmentCard key={assesment.id} assesment={assesment}/>}
        <Stepper ref={stepperRef} style={{ flexBasis: '50rem', width:1200 }}>
            {
             nodesMine.map( n => 
              <StepperPanel header={n.key}>
                <div >
                    <div className="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium p-2">
                    <h2>Descripción</h2>{n.pillar.description}
                    <h2>Ejemplos</h2>{n.pillar.example}
                    <h2>Escala</h2>
                    <h2>Atributos</h2>
                    <ol>{
                      n.children.map( c =>
                        <li key={c.key}>{c.key}</li>
                      )
                    }</ol>
                    <h2>Retroalimentación</h2>
                    <ol>{
                      n.children.map( c =>
                        <li key={c.key}><h3>{c.key}</h3>
                        <p className="mr-5"><b>Descripción:</b>{c.attribute.description}</p>
                        <p className="mr-5"><b>Ejemplo:</b>{c.attribute.example}</p>
                        {c.data.map((a)=><Answer key={a.id} answer={a} disabled={!allowAssesment.edit} />)}
                        <br/>
                        </li>
                      )
                    }</ol>                    
                    </div>
                </div>
                <div className="flex pt-4 justify-content-between">
                    <Button label="Anterior"  icon="pi pi-arrow-left" severity="secondary" 
                      onClick={() => stepperRef.current.prevCallback()}
                      disabled={nodesMine.indexOf(n)===0}
                    />
                    <Button label="Finalizar" icon="pi pi-end" iconPos="center"
                      tooltip={percentCompleted!==100?"Debe finalizar completamente la evaluación para poder finalizar":"Finalizar la evaluación"}
                      disabled={percentCompleted!==100}
                      visible={allowAssesment.edit}
                      onClick={openChangeStatus} />
                    <Button label="Siguiente" icon="pi pi-arrow-right" iconPos="right"
                      disabled={nodesMine.indexOf(n)===(nodesMine.length-1)}
                      onClick={() => stepperRef.current.nextCallback()}
                    />                     
                </div>
              </StepperPanel>
             )
            }
        </Stepper>
        </div>
        </div>
        <AssesmentDialogChangeStatus
            visible={changeStatusAssesmentDialog}
            onHide={hideChangeStatusDialog}
            assesment={assesment}
        />

      </QueryResult>
    
    ); // return
};