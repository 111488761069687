import React from 'react';
import { 
  useEffect  
, useState
, useRef} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { useNavigate } from 'react-router-dom';

import { 
  LicenseKeyDialog
, LicenseKeyDialogSend 
, LicenseKeyDialogDelete
} from './LicenseKeyDialog.tsx';

import { StatusColumn } from "../components/statusColumn.tsx"


export const LicenseKeys = ({license_keys}) => {
    let emptyLicenseKey = {
        id: null,
        description: '',
        licenseKey: '',
        candidateEmail:'',
        questionnaireId:null
    };

    const { user } = useAuth0();
    const userIs = {
      supervisor : user ? user.rolesId.includes("Supervisor") : false
    , coach      : user ? user.rolesId.includes("Coach") : false
    }

    const [licenseKeys, setLicenseKeys] = useState(license_keys);
    useEffect(() => {
        setLicenseKeys(license_keys)
    },[license_keys]);
    
    const [licenseKeyDialog, setLicenseKeyDialog] = useState(false);
    const [deleteLicenseKeyDialog, setDeleteLicenseKeyDialog] = useState(false);
    const [sendLicenseKeyDialog, setSendLicenseKeyDialog] = useState(false);
    const [licenseKey, setLicenseKey] = useState(emptyLicenseKey);
    
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();

    const onRowSelect = (event) => 
        navigate(`/license/${event.data.id}`)    
    ;

    const openNew = () => {
        setLicenseKey(emptyLicenseKey);
        setLicenseKeyDialog(true);
    };

    const header = 
        <div className="flex align-items-center justify-content-between">  
            <span className="text-xl text-900 font-bold">License Keys</span>

            {userIs.coach && 
            <Button _label="Nueva Liceensia" icon="pi pi-plus" 
                rounded  className="mr-2" 
                severity="success"
                tooltip='Crear una evaluación nueva'
                onClick={openNew}
            />
            }
        </div>
    ;

    const hideDialog = (result) => {
        if(result && result.licenseKey) {
            const license_key_mutation = result.licenseKey;
            let _licenseKeys = [...licenseKeys];
            let operation = ""
            const index = _licenseKeys.findIndex( e => parseInt(e.id) === parseInt(license_key_mutation.id));
            if(index === -1) {
              operation = "creado"
              _licenseKeys.push(license_key_mutation);
            } else {
              operation = "actualizado"
              _licenseKeys[index] = license_key_mutation;
            }
            setLicenseKeys(_licenseKeys);
  
            if(toast && toast.current) {
                toast.current.show({
                    severity: 'success', 
                    summary: 'Successful', 
                    detail: `Llave ${operation}`, 
                    life: 3000 
                });
            }
        }        
        setLicenseKeyDialog(false);        
    };

    const hideDeleteLicenseKeyDialog = (event,result) => {
        if(result) {
            if(result.license_key_delete.success) {
                let _licenseKeys = licenseKeys.filter((val) => parseInt(val.id) !== parseInt(result.license_key_delete.licenseKey.id));
                setLicenseKeys(_licenseKeys);
                if(toast && toast.current) {
                    toast.current.show({ 
                        severity: 'success', 
                        summary: 'Successful',
                        detail: 'Licence Key Deleted', 
                        life: 3000 
                    });
                }
            } else {
                console.error(`${result.license_key_delete.message}`)
                if(toast && toast.current) {
                    toast.current.show({ 
                        severity: 'error', 
                        summary: 'Error',
                        detail: `No se pudo eliminar la Licencia`, 
                        life: 6000 
                    });
                }
            }
        }
        setDeleteLicenseKeyDialog(false);
    };

    const hideSendDialog = () => {        
        setSendLicenseKeyDialog(false);
        setLicenseKey(emptyLicenseKey);
    };    


    const editLicenseKey = (licenseKey) => {
        setLicenseKey({ ...licenseKey });
        setLicenseKeyDialog(true);
    };

    const sendLicenseKey = (licenseKey) => {
        setLicenseKey({ ...licenseKey });
        setSendLicenseKeyDialog(true);
    };

    const startToolbarTemplate = () =>
        <div className="flex flex-wrap gap-2">
            <Button label="Nueva Licencia" icon="pi pi-plus" severity="success" onClick={openNew} 
                tooltip='Crear una nueva licencia'
            />
        </div>

    const confirmDeleteLicenseKey = (licenseKey) => {
        setLicenseKey(licenseKey);
        setDeleteLicenseKeyDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2"  onClick={() => editLicenseKey(rowData)}          tooltip='Editar la licencia'/>
                <Button icon="pi pi-send"   rounded outlined className="mr-2"  onClick={() => sendLicenseKey(rowData)}          tooltip='Notificar la licencia'/>
                <Button icon="pi pi-trash"  rounded outlined severity="danger" onClick={() => confirmDeleteLicenseKey(rowData)} tooltip='Eliminar la licencia'/>
            </React.Fragment>
        );
    };

    return(
        <div >
        <Toast ref={toast} />
        {userIs.coach && <Toolbar className="mb-4" start={startToolbarTemplate} ></Toolbar>}
        <DataTable value={licenseKeys} header={header} tableStyle={{ minWidth: '50rem' }}
            selectionMode="single" 
            onRowSelect={onRowSelect}
            size='small'
        >                        
            <Column field="createdBy.name" header="Coach"/>
            <Column field="candidate.name" header="Coachee"/>
            <Column field="completed" header="Completado"/>
            <Column field="status.id" header="Status" body={StatusColumn}/>
            
            <Column body={userIs.coach && actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }} 
                    bodyStyle={{ textAlign: 'right' }}/>
        </DataTable>

        <LicenseKeyDialog
            visible={licenseKeyDialog}
            onHide={hideDialog}
            licenseKey={licenseKey}
        />
        <LicenseKeyDialogDelete
            visible={deleteLicenseKeyDialog}
            onHide={hideDeleteLicenseKeyDialog}
            licenseKey={licenseKey}
        />
        <LicenseKeyDialogSend
            visible={sendLicenseKeyDialog}
            onHide={hideSendDialog}
            licenseKey={licenseKey}
        />
        </div>
    )
    /*
                <Column field="licenseKey" header="Llave"/>
            <Column field="description" header="Descripción"/>
    */
}