import React from "react";
import { useState } from 'react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner';
import { Assesments } from '../components/Assesments.tsx';
import { Toast } from 'primereact/toast';

import { 

 RelationshipSelectButton
} from '../components/RelationshipToggle.tsx';

import { 
    AssesmentSummary
  , AssesmentPillarSummary 
  , AssesmentAttributeSummary
  , AssesmentDetail
  , AssesmentRadar
} from '../components/AssesmentSummary.tsx';

export const GET_ASSESMENTS = gql`
query AssesmentsQ ($candidateId:Int){
  assesments (candidateId: $candidateId){
    id
    name
    description
    completed
    user { code:id name email  }
    candidate { code:id name email }
    relationship { code:id name  color }
    status { code:id name  color }
  }
  assesment_summary (candidateId: $candidateId){
    candidateId
    pillar {
      id name
    }    
    attribute {
      id name
    }
    question {
      id name      
    }    
    Ninguno
    Yo
    Jefe
    Par
    Colaborador
    Familia
    Demas    
    value
  }
}
`
export const AssesmentsPage = () => {

  const location = useLocation();
  const toast = React.useRef<Toast>(null);
  const [pillar, setPillar] = React.useState(null);

  const SearchParam = new URLSearchParams(location.search)
  const candidateIdStr = SearchParam.get('candidateId');
  
  let candidateId
  if(candidateIdStr && isNaN(candidateIdStr)) {
    candidateId = 0
  } else {
    candidateId = parseInt(candidateIdStr);
  }

  const {loading, error, data} = useQuery(GET_ASSESMENTS, {
    variables: {
      candidateId: candidateId
    }
  });


  let assesment_summary_by_Pillar
  let assesment_summary_by_Attribute
  if(data?.assesment_summary) {

    // Reduce -- summarize by PillaR
    assesment_summary_by_Attribute = data.assesment_summary
    .reduce( function(r,a)  {
        const rKkey = a.attribute.name +"<>"+a.pillar.name
        r[rKkey] = r[rKkey] || {
          pillar: a.pillar
        , attribute: a.attribute
        , Ninguno:0
        , Yo:0
        , Jefe:0
        , Par:0
        , Colaborador:0
        , Familia:0
        , Demas:0
        , value:0
        , count:0
        };
        r[rKkey].Ninguno += a.Ninguno;
        r[rKkey].Yo += a.Yo;
        r[rKkey].Jefe += a.Jefe;
        r[rKkey].Par += a.Par;
        r[rKkey].Colaborador += a.Colaborador;
        r[rKkey].Familia += a.Familia;
        r[rKkey].Demas += a.Demas;
        r[rKkey].value += a.value;
        r[rKkey].count++;        
        return(r);
      },{}) // reduce
      ;

    for(let r in assesment_summary_by_Attribute) {
      assesment_summary_by_Attribute[r].Yo /= assesment_summary_by_Attribute[r].count;
      assesment_summary_by_Attribute[r].Jefe /= assesment_summary_by_Attribute[r].count;
      assesment_summary_by_Attribute[r].Par /= assesment_summary_by_Attribute[r].count;
      assesment_summary_by_Attribute[r].Colaborador /= assesment_summary_by_Attribute[r].count;
      assesment_summary_by_Attribute[r].Familia /= assesment_summary_by_Attribute[r].count;
      assesment_summary_by_Attribute[r].Demas /= assesment_summary_by_Attribute[r].count;
      assesment_summary_by_Attribute[r].value /= assesment_summary_by_Attribute[r].count;        
    }
    assesment_summary_by_Attribute = Object
    .keys(assesment_summary_by_Attribute)
    .map(p => assesment_summary_by_Attribute[p] );
    
    // Reduce -- summarize by PillaR
    assesment_summary_by_Pillar = data.assesment_summary
    .reduce( function(r,a)  {
        r[a.pillar.name] = r[a.pillar.name] || {
          pillar: a.pillar
        , Ninguno:0
        , Yo:0
        , Jefe:0
        , Par:0
        , Colaborador:0
        , Familia:0
        , Demas:0
        , value:0
        , count:0
        };
        r[a.pillar.name].Ninguno += a.Ninguno;
        r[a.pillar.name].Yo += a.Yo;
        r[a.pillar.name].Jefe += a.Jefe;
        r[a.pillar.name].Par += a.Par;
        r[a.pillar.name].Colaborador += a.Colaborador;
        r[a.pillar.name].Familia += a.Familia;
        r[a.pillar.name].Demas += a.Demas;
        r[a.pillar.name].value += a.value;
        r[a.pillar.name].count++;        
        return(r);
      },{}) // reduce
      ;

    for(let r in assesment_summary_by_Pillar) {
        assesment_summary_by_Pillar[r].Yo /= assesment_summary_by_Pillar[r].count;
        assesment_summary_by_Pillar[r].Jefe /= assesment_summary_by_Pillar[r].count;
        assesment_summary_by_Pillar[r].Par /= assesment_summary_by_Pillar[r].count;
        assesment_summary_by_Pillar[r].Colaborador /= assesment_summary_by_Pillar[r].count;
        assesment_summary_by_Pillar[r].Familia /= assesment_summary_by_Pillar[r].count;
        assesment_summary_by_Pillar[r].Demas /= assesment_summary_by_Pillar[r].count;
        assesment_summary_by_Pillar[r].value /= assesment_summary_by_Pillar[r].count;        
    }
  }

  const onRowSelect = (event) => {
    const pillar = event?.data?.pillar || event?.value?.pillar;
    const attribute = event?.data?.attribute || event?.value?.attribute;
    setPillar(pillar)
    if(toast && toast.current)
      toast.current.show({ 
        severity: 'info'
      , summary: 'Row Selected'
      , detail: `${pillar?.name ? ("Pilar:"+pillar?.name):""} || ${attribute?.name ? ("Atributo:"+attribute?.name):""} `
      , life: 3000 
    });
  };

  const [selectedRelationships, setSelectedRelationships] = useState<string[]>();
  const onSelectedRelationshipChange = ( relationships:string[]) => {
        setSelectedRelationships(relationships);
  };  

  return (
    <div><p>Listado de las evaluaciones a las cuales tiene acceso</p>
     <Toast ref={toast} />
      <QueryResult error={error} loading={loading} data={data} >
        
        <Assesments assesments={data?.assesments}/>

        {candidateIdStr &&
        <div>
          <AssesmentSummary assesment_summary_by_pillar={assesment_summary_by_Pillar}/> 

          <RelationshipSelectButton
            selectedRelationships={selectedRelationships || []}
            onChange={onSelectedRelationshipChange}
          /> 

          <div className="flex flex-wrap justify-content-center">          
           <div className="flex flex-wrap flex-grow-1">
             <AssesmentPillarSummary
               assesment_summary_by_pillar={assesment_summary_by_Pillar}
               showRelationships={selectedRelationships || []}
               onRowSelect={onRowSelect}
            />
           </div>
           <div className="flex flex-wrap flex-grow-1">
             <AssesmentRadar 
               assesment_summary_by_pillar={assesment_summary_by_Pillar}
               showRelationships={selectedRelationships || []}
             />
           </div>          
          </div>
          
         <div className="flex flex-wrap">
          <AssesmentAttributeSummary 
            assesment_summary={assesment_summary_by_Attribute} 
            showRelationships={selectedRelationships || []}
            onRowSelect={onRowSelect} 
            pillar={pillar}
          /> 
          <AssesmentDetail
            assesment_summary={data?.assesment_summary} 
            showRelationships={selectedRelationships || []}
            onRowSelect={onRowSelect} 
            pillar={pillar}
          />
         </div>
         </div>
         }
        
        
      </QueryResult>
    </div>
  );
};

export default withAuthenticationRequired(AssesmentsPage, {
  onRedirecting: () => <SpinnerContainer />,
});