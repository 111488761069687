import React from  "react";
import { useRef } from  "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';


export const ApolloProviderWithAuth0 = ({ children }) => {

  const {  getAccessTokenSilently } = useAuth0();
  
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI, 
    //uri: 'http://localhost:4000/graphql',
  });
  
  const setAuthorizationLink  = setContext(async (_, { headers, ...rest }) => {
    let token;

    try {
      token = await getAccessTokenSilently();      
    } catch (error) {
      console.log(error);
    }

    console.log("Token",token)
    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
  

  const client = useRef();

  if (!client.current) {
    client.current = new ApolloClient({
      name: 'three60',
      version: '1.0',
      ssrMode: true,      
      assumeImmutableResults: true,  
      queryDeduplication: false,
      link: setAuthorizationLink.concat(httpLink),
      cache: new InMemoryCache()
    });
  }

  return (
    <ApolloProvider client={client.current}>{children}</ApolloProvider>
  );
};

//export default withAuth0( ApolloProviderWithAuth0 );