import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useQuery, gql } from '@apollo/client';

import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner/index.js';
import { AssesmentsByRelationship, Assesments } from '../components/Assesments.tsx';
import { StatusColumn } from '../components/statusColumn.tsx';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { LicenseKeyDialogChangeStatus }  from "../components/LicenseKeyDialog.tsx"
        

export const GET_LICENSEKEYS = gql`
query licenseKey($id:Int) {
  license_key (id:$id){
    licenseKey
    id
    description
    createdBy {
      name
      email
    }
    candidate {
      id
      code:id
      name
      email
    }
    status { id code:id name  color }
    assesments {
      id
      name
      description
      completed
      user { id code:id email name }
      candidate { id code:id email name }
      relationship { id code:id name color minimum maximum}
      status { id code:id name  color }
    }
  }
}
`

export const LicenseKeyCard = ({licenseKey}) =>{
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [dialogVisible_1, setDialogVisible_1] = useState<boolean>(false);

  const hideChangeStatusDialog = () => {
      setDialogVisible(false);
      setDialogVisible_1(false);
  };
    
  const openChangeStatus = () => {
      setDialogVisible(true);
  };
  const openChangeStatus_1 = () => {
    setDialogVisible_1(true);
};  

  const footer =
  <>
    <Button size="small" severity="warning" label="Cerrar evaluación" icon="pi pi-lock" onClick={openChangeStatus}/>  
    <Button size="small" severity="info" label="Set New" icon="pi pi-lock" onClick={openChangeStatus_1}/>  
  </>

  return(
    <Card
      title={licenseKey?.name }
      subTitle={licenseKey?.description }
      className="surface-ground mt-5 mb-5"
      footer={footer}
    >
      <p>Coach: &nbsp;{licenseKey?.createdBy?.name}</p>
      <p>Coachee: &nbsp;{licenseKey?.candidate?.name || "No asignado"}</p>
      <StatusColumn status={licenseKey.status}/>
      <LicenseKeyDialogChangeStatus
        visible={dialogVisible}
        onHide={hideChangeStatusDialog}
        licenseKey={licenseKey}
        statusId={5}
      />
      <LicenseKeyDialogChangeStatus
        visible={dialogVisible_1}
        onHide={hideChangeStatusDialog}
        licenseKey={licenseKey}
        statusId={1}
      />      
    </Card>
  )//<p>Llave: &nbsp;{licenseKey?.licenseKey}</p>
}


export const LicensesKeyPage = () => {
  const {licenseKeyId}=useParams();

  const {loading, error, data} = useQuery(GET_LICENSEKEYS, {
    variables: {
      id: Number(licenseKeyId)
    }
  });

  return (
    <div className="card flex flex-wrap justify-content-center gap-2">
    <div>
      <h1>Evaluación</h1>
      <QueryResult error={error} loading={loading} data={data} >
        <LicenseKeyCard licenseKey={data?.license_key[0]}/>
        <div style={{width:900}}>
        <p>Como coachee, debera ingresar el coreo electronicóo y el nombre de las personas que le proveeran retroalimentación (evaluadores). Deberá especificar la relación que el evaluador tiene con usted</p>
        <p>Una ves que complete la lista, podrá enviarles una notificación por correo elecronico el cual les indicará su participación en esta evaluación. La notificación explicará el proceso junto con las instruciones de como utilizar esta herramienta</p>
        </div>
        <div className="flex flex-grow-1">
        <Assesments 
          assesments={data?.license_key[0].assesments} 
          licenseKey={data?.license_key[0]}
        />
        <div class="flex  justify-content-center p-4 w-25rem">
        <AssesmentsByRelationship
          assesments={data?.license_key[0].assesments}
          licenseKey={data?.license_key[0]}
        />
        </div>
        </div>        
      </QueryResult>
    </div>
    </div>
  );
};

export default withAuthenticationRequired(LicensesKeyPage, {
  onRedirecting: () => <SpinnerContainer />,
});