//import logo from './logo.svg';
import './App.css';


import { BrowserRouter, Routes, Route } from "react-router-dom";

import * as pages from "./pages/index.ts";
/* en lugar de importarlos uno a uno se importa todo
import { Pillars } from './pages/pillar.tsx';
*/
import Profile from "./pages/Profile.tsx";
import AssesmentsPage from "./pages/assesments.tsx";
import CoacheesPage from "./pages/coahees.tsx";
import LicenseKeysPage from "./pages/licenseKeys.tsx";
import LicenseKeyPage from "./pages/licenseKeyPage.tsx";
import RedeemPage from "./pages/redeem.tsx";

import BasicDemo from "./components/NavBar.tsx"

function App() {
  return (
      <BrowserRouter>
      <BasicDemo/>
       <Routes>
       
       <Route exact path="/" element={<AssesmentsPage />} />

       <Route exact path="/profile" element={<Profile />} />
       <Route exact path="/pillars" element={<pages.Pillars />} />
       <Route exact path="/pillar/:pillarId" element={<pages.Pillar />} />
       <Route exact path="/assesments" element={<AssesmentsPage />} />
       <Route exact path="/assesment/:assesmentId" element={<pages.Assesment />} />
       <Route exact path="/users" element={<CoacheesPage />} />
       <Route exact path="/license" element={<LicenseKeysPage />} />
       <Route exact path="/license/:licenseKeyId" element={<LicenseKeyPage />} />
       <Route exact path="/redeem/:licenseKey" element={<RedeemPage />} />
       </Routes>
      </BrowserRouter>
  );
}

export default App;

/*
<Route exact path="/license/:licenseKey" element={<LicenseKeyPage />} />
**/